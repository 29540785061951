@import url(https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500&display=swap);
:root {
    --background-color: #e4e4e1;
    --submit-button-color: #9ec317;
    --hover-button-color: #f50057;
    --next-button-color: #de6b04;
    --text-color: #999898;
    --peer-shadow: 0px/* 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12) */
    ;
    --peer-border: 0px/* solid rgba(49, 49, 49, 0.9) */
    ;
    --peer-empty-avatar: url(../../static/media/buddy.4a0790de.svg);
    --peer-bg-color: rgba(49, 49, 49, 0);
    --peer-video-bg-color: rgba(19, 19, 19, 0);
    --active-speaker-border-color: rgba(255, 255, 255, 0);
    --selected-peer-border-color: rgba(55, 126, 255, 0);
    --active-speaker-shadow: none;
}

html {
    height: 100%;
    width: 100%;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-weight: 300;
    margin: 0;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

body {
    height: 100%;
    width: 100%;
    font-size: 16px;
    margin: 0;
    background-color: #919191!important;
}

#SecondDoor {
    height: 100%;
    width: 100%;
    background-color: #f4f4f4;
    overflow: hidden;
}
